import { FcBriefcase, FcComments, FcContacts, FcFile, FcPicture, FcSupport } from 'react-icons/fc';
import { NavLink } from 'react-router-dom';

const menu = [
    {
      id: 1,
      name: 'Portfolio',
      href: '/portfolio',
      imageSrc: <FcBriefcase className='text-8xl'/>,
      imageAlt: "Briefcase",
    },
    {
        id: 2,
        name: 'Resume',
        href: '/resume',
        imageSrc: <FcFile className='text-8xl'/>,
        imageAlt: "resume",
    },
    {
        id: 3,
        name: 'Quotes',
        href: '/quotes',
        imageSrc: <FcComments className='text-8xl'/>,
        imageAlt: "quote",
    },
    {
        id: 4,
        name: 'Contact',
        href: '/contact',
        imageSrc: <FcContacts className='text-8xl'/>,
        imageAlt: "contact",
    },
    {
        id: 5,
        name: 'Gallery',
        href: '/gallery',
        imageSrc: <FcPicture className='text-8xl'/>,
        imageAlt: "gallery",
    },
    {
        id: 5,
        name: 'Skills',
        href: '/skills',
        imageSrc: <FcSupport className='text-8xl'/>,
        imageAlt: "gallery",
    },
  ]
  
  export default function AboutSm() {
    return (
      <div >
        <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          
  
          <div className=" grid grid-cols-3 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {menu.map((menu) => (
              <div key={menu.id} className="group relative">
                <div className=" flex justify-center  shadow-md  rounded-md overflow-hidden">
                  <NavLink key={menu.key} to={menu.href} >{menu.imageSrc}</NavLink>
                </div>
                <div className="flex justify-center">
                  <div>
                    <h3 className="text-md text-center font-sans text-white">
                      {menu.name}
                    </h3>
                    
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  