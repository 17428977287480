export const Quotes = () => {
    return (
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-10 row-gap-8 lg:grid-cols-5">
          <div className="lg:col-span-2">
            
            <div className="mb-3">
                <p className="inline-block text-white font-serif text-xl font-extrabold leading-none tracking-tight lg:text-4xl xl:text-5xl">
                Intellect is not wisdom
                </p>
            </div>
            <p className="mb-4 text-base text-gray-400 md:text-lg">
            Freedom has cost too much blood and agony to be relinquished at the cheap price of rhetoric.
            </p>
            <div className="flex items-center">
                <img
                  alt="avatar"
                  src="/ths_1.jpg"
                  className="object-cover w-10 h-10 rounded-full shadow-sm"
                />
              <div className="mx-2">
                <p className="font-semibold text-fuchsia-600 transition-colors duration-200 hover:text-deep-purple-accent-400"
                >
                  Thomas Sowell
                </p>
                <p className="text-sm font-medium leading-4 text-gray-400">
                Economist &amp; Author
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-8 lg:col-span-3">
            <div>
              <div className="mb-3">
                  <p className="inline-block text-white font-serif text-xl font-extrabold leading-none tracking-tight lg:text-2xl">
                  He who is not courageous enough to take risks will accomplish nothing in life
                  </p>
              </div>
              <p className="mb-4 text-base text-gray-400 md:text-lg">
              A man who views the world the same at 50 as he did at 20 has wasted 30 years of his life.
              </p>
              <div className="flex items-center">
                  <img
                    alt="avatar"
                    src="/ma_1.jpg"
                    className="object-cover w-10 h-10 rounded-full shadow-sm"
                  />
                <div className="mx-2">
                  <p
                    className="font-semibold text-fuchsia-600 transition-colors duration-200 hover:text-deep-purple-accent-400"
                  >
                    Muhammad Ali
                  </p>
                  <p className="text-sm font-medium leading-4 text-gray-400">
                    Boxer &amp; Father
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="mb-3">
                  <p className="inline-block text-white font-serif text-xl font-extrabold leading-none tracking-tight lg:text-2xl">
                  Service to others is the rent you pay for your room here on earth
                  </p>
              </div>
              <p className="mb-4 text-base text-gray-400 md:text-lg">
              To live is to choose. But to choose well, you must know who you are and what you stand for, where you want to go and why you want to get there.
              </p>
              <div className="flex items-center">
                  <img
                    alt="avatar"
                    src="/kf_1.jpg"
                    className="object-cover w-10 h-10 rounded-full shadow-sm"
                  />
                <div className="mx-2">
                  <p
                    className="font-semibold text-fuchsia-600 transition-colors duration-200 hover:text-deep-purple-accent-400"
                  >
                    Kofi Annan
                  </p>
                  <p className="text-sm font-medium leading-4 text-gray-400">
                  Diplomat &amp; Humanitarian
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };