import { FaGithub, FaGoogle, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

export default function Contact(){
    return (
        <>
            <div className="hidden sm:flex flex-row items-center h-5/6">
                <div className="flex-none w-1/2 flex-row items-center animate-fade-right">
                    <div className="flex flex-col justify-center items-center">
                    <img className="h-1/2 w-1/2 rounded-l-full rounded-tr-full shadow-md ring-4 ring-offset-2 ring-purple-600" src="/avatar-1.png" alt="avatar" />
                    </div>
                </div>
                <div className="basis-3/4 -ml-4 animate-fade-left">
                    <p className="font-sans font-light text-xl sm:text-3xl xl:text-4xl text-white">Hi I'm</p>
                    <h1 className="font-serif font-black text-2xl sm:text-5xl xl:text-6xl text-white">Akande Victor</h1>
                    <p className="font-sans font-thin text-xl xl:text-2xl text-white">Software Engineer | Cloud Developer</p>
                    <p className="font-sans text-lg xl:text-xl text-white">I am currently working with other engineers on the World Bank funded Federated Genomics Cloud</p>
                    <div className="flex flex-row mt-2 gap-4">
                        <div>
                            <a alt="linkedin" href="https://linkedin.com/in/victor-akande-570a35148/" ><FaLinkedinIn className="text-2xl text-white hover:text-fuchsia-600"  /></a>
                        </div>
                        <div>
                            <a alt="twitter" href="https://twitter.com/akandeav/" ><FaTwitter className="text-2xl text-white hover:text-fuchsia-600" /></a>
                        </div>
                        <div>
                            <a alt="github" href="https://github.com/akandeav/" ><FaGithub className="text-2xl text-white hover:text-fuchsia-600" /></a>
                        </div>
                        <div>
                            <a alt="email" href="mailto:akandevic@gmail.com" ><FaGoogle className="text-2xl text-white hover:text-fuchsia-600" /></a>
                        </div>
                        <div>
                            <a alt="github" href="https://instagram.com/akandeav/" ><FaInstagram className="text-2xl text-white hover:text-fuchsia-600" /></a>
                        </div>
                    </div> 
                </div>
            </div>
            <div className="sm:hidden  flex flex-col space-y-4 justify-center items-center ">
                <div className="w-1/2 mt-12">
                    <div className="flex flex-col justify-center items-center">
                    <img className="h-1/2 w-1/2 rounded-l-full rounded-tr-full shadow-md ring-4 ring-offset-2 ring-purple-600" src="/avatar-1.png" alt="avatar" />
                    </div>
                </div>
                <div className="basis-3/4 text-center p-2">
                    <p className="font-sans font-light  text-xl sm:text-3xl xl:text-4xl text-white">Hi I'm</p>
                    <h1 className="font-serif font-black text-2xl sm:text-5xl xl:text-6xl text-white">Akande Victor</h1>
                    <p className="font-sans font-thin text-lg xl:text-2xl text-white">Software Engineer | Cloud Developer</p>
                    <p className="font-sans  text-lg xl:text-2xl text-white">I am currently working with other engineers on the World Bank funded Federated Genomics Cloud</p>
                
                </div>
                <div className="flex flex-row mt-2 gap-4">
                    <div>
                        <a alt="linkedin" href="https://linkedin.com/in/victor-akande-570a35148/" ><FaLinkedinIn className="text-2xl text-white hover:text-fuchsia-600"  /></a>
                    </div>
                    <div>
                        <a alt="twitter" href="https://twitter.com/akandeav/" ><FaTwitter className="text-2xl text-white hover:text-fuchsia-600" /></a>
                    </div>
                    <div>
                        <a alt="github" href="https://github.com/akandeav/" ><FaGithub className="text-2xl text-white hover:text-fuchsia-600" /></a>
                    </div>
                    <div>
                        <a alt="email" href="mailto:akandevic@gmail.com" ><FaGoogle className="text-2xl text-white hover:text-fuchsia-600" /></a>
                    </div>
                    <div>
                        <a alt="github" href="https://instagram.com/akandeav/" ><FaInstagram className="text-2xl text-white hover:text-fuchsia-600" /></a>
                    </div>
                </div> 
            </div>
            
        </>
    )
}