import { DiPython, DiReact, DiDocker, DiDjango, DiJsBadge, DiLinux, DiMysql, DiHeroku, DiGit,  DiRasberryPi, DiHtml5, DiAws } from 'react-icons/di';
import { SiKubernetes, SiOpenstack, SiPostgresql, SiGoland, SiCelery,  } from 'react-icons/si'
 
const menu = [
    {
        id: 1,
        name: 'Python',
        imageSrc: <DiPython className='text-6xl text-green-700'/>,
        imageAlt: "Python",
    },
    {
        id: 2,
        name: 'React',
        imageSrc: <DiReact className='text-6xl text-cyan-600'/>,
        imageAlt: "React",
    },
    {
        id: 3,
        name: 'GO!',
        imageSrc: <SiGoland className='text-8xl text-cyan-500'/>,
        imageAlt: "go",
    },
    {
        id: 4,
        name: 'Javascript',
        imageSrc: <DiJsBadge className='text-8xl text-orange-700'/>,
        imageAlt: "JS",
    },
    {
        id: 5,
        name: 'Django',
        imageSrc: <DiDjango className='text-6xl text-green-700'/>,
        imageAlt: "django",
    },
    {
        id: 6,
        name: 'Linux',
        imageSrc: <DiLinux className='text-6xl text-yellow-500'/>,
        imageAlt: "linux",
    },
    {
        id: 7,
        name: 'MySQL',
        imageSrc: <DiMysql className='text-6xl text-blue-800'/>,
        imageAlt: "mysql",
    },
    {
        id: 8,
        name: 'PostgreSQL',
        imageSrc: <SiPostgresql className='text-6xl text-blue-700'/>,
        imageAlt: "postgres",
    },
    {
        id: 9,
        name: 'Heroku',
        imageSrc: <DiHeroku className='text-6xl text-purple-700'/>,
        imageAlt: "gallery",
    },
    {
        id: 10,
        name: 'Kubernetes',
        imageSrc: <SiKubernetes className='text-6xl text-cyan-700'/>,
        imageAlt: "k8s",
    },
    {
        id: 11,
        name: 'RaspberryPi',
        imageSrc: <DiRasberryPi className='text-6xl text-red-700'/>,
        imageAlt: "pi",
    },
    {
        id: 12,
        name: 'HTML',
        imageSrc: <DiHtml5 className='text-6xl text-orange-400'/>,
        imageAlt: "html",
    },
    {
        id: 13,
        name: 'Docker',
        imageSrc: <DiDocker className='text-8xl text-blue-800'/>,
        imageAlt: "docker",
    },
    {
        id: 14,
        name: 'Git',
        imageSrc: <DiGit className='text-6xl text-orange-500'/>,
        imageAlt: "git",
    },
    {
        id: 15,
        name: 'Openstack',
        imageSrc: <SiOpenstack className='text-6xl text-red-600'/>,
        imageAlt: "Openstack",
    },
    {
        id: 16,
        name: 'AWS',
        imageSrc: <DiAws className='text-6xl text-yellow-600'/>,
        imageAlt: "aws",
    },
    {
        id: 17,
        name: 'Celery',
        imageSrc: <SiCelery className='text-6xl text-green-600'/>,
        imageAlt: "celery",
    },
  ]
  
  export default function Skills() {
    return (
      <div >
        <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className=" grid grid-cols-3 items-center  gap-y-10 gap-x-6 sm:grid-cols-4 lg:grid-cols-5 xl:gap-x-8">
            {menu.map((menu) => (
              <div key={menu.id} className="group relative">
                <div className=" flex justify-center h-24 overflow-hidden">
                  {menu.imageSrc}
                </div>
                <div className="flex justify-center">
                  <div>
                    <h3 className="text-md text-center font-sans text-gray-300">
                      {menu.name}
                    </h3>
                    
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  