import NavBar from "../components/Navbar/Navbar";
import About from "./About";
import { Resume } from "./Resume";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Quotes } from "./Quotes";
import Contact from "./Contact";
import Portfolio from "./Portfolio";
import Gallery from "./Gallery";
import Skills from "./Skills";


function Example() {
    return (
        <HashRouter>
        <div className="relative h-screen  bg-gradient-to-br from-purple-200 to-fuchsia-400">
            <div className="">
                <div className="relative">
                    <div className="container mx-auto px-4 ">
                        <div className="flex h-screen items-center">
                            <div className="animate-fade mx-auto w-full h-2/3 lg:h-5/6 shadow-xl rounded-lg bg-zinc-800">
                                
                                    <div className="static h-auto">
                                        <NavBar />
                                    </div>
                                    <div className="overflow-auto mx-auto w-full h-5/6">
                                        <Routes>
                                            <Route exact path="/" element={<About />}/>
                                            <Route path="/resume" element={<Resume />} />
                                            <Route path="/quotes" element={<Quotes />} />
                                            <Route path="/contact" element={<Contact />} />
                                            <Route path="/portfolio" element={<Portfolio />} />
                                            <Route path="/gallery" element={<Gallery />} />
                                            <Route path="/skills" element={<Skills />} />
                                        </Routes>
                                    </div>
                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        </HashRouter>
    )
}
export default function Home() {
    return (
        <Example />
    )
}