import bc1 from '../assets/img/bc1_0.jpg'
import bc2 from  '../assets/img/bc1_1.jpg'
import bc3 from '../assets/img/bc1_2.jpg'
import bc4 from  '../assets/img/bc1_3.jpg'
import img1 from '../assets/img/bc2_0.JPG'
import img2 from  '../assets/img/bc2_1.JPG'
import img3 from '../assets/img/bc2_2.JPG'
import img4 from  '../assets/img/bc2_3.JPG'
import pi1 from '../assets/img/pi_0.JPG'
import pi2 from  '../assets/img/pi_1.JPG'
import pi3 from '../assets/img/pi_2.JPG'
import pi4 from  '../assets/img/pi_3.JPG'
const page = [
  {
    id: 1,
    name: 'Fedgen Workshop',
    date: 'May 4, 2022',
    detail: 'In this workshop I was invited to speak on Deploymaent of Openstack on a multi-node cluster and installation of jupyterhub for AI in precision medicine. It was fun and very hands-on.',
    img1: img1,
    img2: img2,
    img3: img3,
    img4: img4
  },
  {
    id: 2,
    name: 'Project HomeLab',
    date: 'April 4, 2022',
    detail: "This is an ongoing project. I am still fascinated by how the cloud works and having the pi's have helped me greatly. I have been using them majorly to sim production environment",
    img1: pi1,
    img2: pi2,
    img3: pi3,
    img4: pi4
  },
  {
    id: 2,
    name: 'Fedgen Bootcamp',
    date: 'August 11, 2021',
    detail: "The main goal of the bootcamp was to finalise in the requirement elicitation of the FEDEGEN HealthEdu App. ",
    img1: bc1,
    img2: bc2,
    img3: bc3,
    img4: bc4
  }
];

export default function Gallery() {
    return (
      <div className="">
        {page.map((gallery) => (
          <>
          
          <div className="max-w-2xl mx-auto py-4 px-4 sm:py-4 sm:px-6 lg:max-w-7xl">
            <div className="relative rounded-lg overflow-hidden lg:h-96">
              <div className="absolute inset-0">
                <img
                  src={gallery.img1}
                  alt=""
                  className="w-full h-full object-center object-cover"
                />
              </div>
              <div aria-hidden="true" className="relative w-full h-96 lg:hidden" />
              <div aria-hidden="true" className="relative w-full h-32 lg:hidden" />
              <div className="absolute inset-x-0 bottom-0 bg-black bg-opacity-50 p-6 rounded-bl-lg rounded-br-lg backdrop-filter backdrop-blur sm:flex sm:items-center sm:justify-between lg:inset-y-0 lg:inset-x-auto lg:w-96 lg:rounded-tl-lg lg:rounded-br-none lg:flex-col lg:items-start">
                <div>
                  <h2 className="text-xl font-bold text-white">{gallery.name}</h2>
                  <p className="mt-1 text-sm text-gray-100">{gallery.date}</p>
                  <p className="mt-1 text-sm text-gray-300">{gallery.detail}</p>
                </div>
              </div>
            </div>
           
         
        </div>
        <div className="max-w-2xl mx-auto py-4 px-4 sm:py-4 sm:px-6 lg:max-w-7xl">
          <div className="relative rounded-lg h-fit  lg:h-96">
          <div className="mt grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
            <div className="group aspect-w-2 aspect-h-1 rounded-lg overflow-hidden sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2">
              <img
                src={gallery.img2}
                alt={gallery.alt}
                className="object-center object-cover group-hover:opacity-75"
              />
              <div aria-hidden="true" className="bg-gradient-to-b from-transparent to-black opacity-50" />
              
            </div>
            <div className="group aspect-w-2 aspect-h-1 rounded-lg overflow-hidden sm:relative sm:aspect-none sm:h-full">
              <img
                src={gallery.img3}
                alt={gallery.alt}
                className="object-center object-cover group-hover:opacity-75 sm:absolute sm:inset-0 sm:w-full sm:h-full"
              />
              <div
                aria-hidden="true"
                className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"
              />
              
            </div>
            <div className="group aspect-w-2 aspect-h-1 rounded-lg overflow-hidden sm:relative sm:aspect-none sm:h-full">
              <img
                src={gallery.img4}
                alt={gallery.alt}
                className="object-center object-cover group-hover:opacity-75 sm:absolute sm:inset-0 sm:w-full sm:h-full"
              />
              <div
                aria-hidden="true"
                className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"
              />
            </div>
          </div>
          </div>
          </div>
        <div >
      </div>
      </>
        ))}
      </div>
    
    )
  }
  