import { FaGithub, FaGoogle, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import AboutSm from './AboutSm';

export default function About(){
    return (
        <>
            <div className="hidden sm:flex flex-row items-center h-5/6">
                <div className="flex-none w-1/2 flex-row items-center">
                    <div className="flex flex-col justify-center items-center">
                    <img className="h-1/2 w-1/2 rounded-l-full rounded-tr-full shadow-md ring-4 ring-offset-2 ring-purple-600" src="/avatar-1.png" alt="avatar" />
                    </div>
                </div>
                <div className="basis-3/4">
                    <p className="font-sans font-light text-xl sm:text-3xl xl:text-4xl text-white">Hi I'm</p>
                    <h1 className="font-serif font-black text-2xl sm:text-5xl xl:text-6xl text-white">Akande Victor</h1>
                    <p className="font-sans font-thin text-xl xl:text-2xl text-white">Software Engineer | Cloud Developer</p>
                    <div className="flex flex-row mt-2 gap-4">
                    <div>
                            <a alt="linkedin" href="https://linkedin.com/in/victor-akande-570a35148/" ><FaLinkedinIn className="text-2xl text-white hover:text-fuchsia-600"  /></a>
                        </div>
                        <div>
                            <a alt="twitter" href="https://twitter.com/akandeav/" ><FaTwitter className="text-2xl text-white hover:text-fuchsia-600" /></a>
                        </div>
                        <div>
                            <a alt="github" href="https://github.com/akandeav/" ><FaGithub className="text-2xl text-white hover:text-fuchsia-600" /></a>
                        </div>
                        <div>
                            <a alt="email" href="mailto:akandevic@gmail.com" ><FaGoogle className="text-2xl text-white hover:text-fuchsia-600" /></a>
                        </div>
                        <div>
                            <a alt="github" href="https://instagram.com/akandeav/" ><FaInstagram className="text-2xl text-white hover:text-fuchsia-600" /></a>
                        </div>
                    </div> 
                </div>
            </div>
            <div className='sm:hidden'>
                <AboutSm />
            </div>
        </>
    )
}