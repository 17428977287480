import { BsCheckCircleFill } from 'react-icons/bs'

const pillFeatures = [
  'Django',
  'DRF',
  'Celery',
  'PostgreSQL',
  'Redis',
  'AWS'
]
const includedFeatures = [
  'Django',
  'DRF',
  'React JS',
  'MySQL',
  'Microservices',
  'Heroku'
]
const fedgenFeatures = [
  'Django & DRF',
  'Openstack',
  'Docker',
  'MySQL',
  'Kubernetes',
  'Linux'
]

export default function Portfolio() {
  return (
    <div className="">
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-fuchsia-600 sm:text-4xl lg:text-5xl">Projects</h2>
            <p className="mt-4 text-xl text-gray-200">
             Here's what I'm up to
            </p>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2'>
      <div className="mt-8 pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 " />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
              <div className="flex-1 bg-white px-6 py-8 lg:p-12">
              <h2 className='text-4xl font-black text-gray-800'>Pillometer</h2>
                <p className="mt-6 text-base text-gray-500">
                Pilometer is a ready-made inventory management suite for any inventory size; complete with its own hardware and software unit.
                </p>
                <div className="mt-8">
                  <div className="flex items-center">
                    <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-fuchsia-600">
                      Skills
                    </h4>
                    <div className="flex-1 border-t-2 border-gray-200" />
                  </div>
                  <ul  className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                    {pillFeatures.map((feature) => (
                      <li key={feature} className="flex items-start lg:col-span-1">
                        <div className="flex-shrink-0">
                          <BsCheckCircleFill className="h-5 w-5 text-purple-400" aria-hidden="true" />
                        </div>
                        <p className="ml-3 text-sm text-gray-700">{feature}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                
                <div className="mt-6">
                  <div className="rounded-md shadow">
                    <a
                      href="https://pillometer.com"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                    >
                      Go to Pillometer
                    </a>
                  </div>
                </div>
                <div className="mt-4 text-sm">
                  <a href="https://admin.pillometer.com" className="font-medium text-gray-900">
                    Pillometer Dashboard
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 " />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
              <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                <img src="/Edibo.png" alt="edibo"className="h-12" />
                <p className="mt-6 text-base text-gray-500">
                  Edibo is a voting web app. Named after a Yoruba phrase that translates as <em>Go Vote</em>, 
                  Edibo is targeted at organisations that need a Hassle-free voting experience.
                </p>
                <div className="mt-8">
                  <div className="flex items-center">
                    <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-fuchsia-600">
                      Skills
                    </h4>
                    <div className="flex-1 border-t-2 border-gray-200" />
                  </div>
                  <ul  className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                    {includedFeatures.map((feature) => (
                      <li key={feature} className="flex items-start lg:col-span-1">
                        <div className="flex-shrink-0">
                          <BsCheckCircleFill className="h-5 w-5 text-purple-400" aria-hidden="true" />
                        </div>
                        <p className="ml-3 text-sm text-gray-700">{feature}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                
                <div className="mt-6">
                  <div className="rounded-md shadow">
                    <a
                      href="https://vote.akande.com.ng"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                    >
                      Go to Edibo
                    </a>
                  </div>
                </div>
                <div className="mt-4 text-sm">
                  <a href="https://github.com/akandeav" className="font-medium text-gray-900">
                    Follow me on github
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 " />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
              <div className="flex-1 bg-white px-6 py-8 lg:p-12 max-h-full">
                <h2 className='text-4xl font-black text-gray-800'>FEDGEN</h2>
                <p className="mt-6 text-base text-gray-500">
                  Still a work in progress, the Federated Genimocs FEDGEN project is aimed towards providing a federated cloud infrastructure for informatics based genomic research in Africa.
                </p>
                <div className="mt-8">
                  <div className="flex items-center">
                    <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-fuchsia-600">
                      Skills
                    </h4>
                    <div className="flex-1 border-t-2 border-gray-200" />
                  </div>
                  <ul  className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                    {fedgenFeatures.map((feature) => (
                      <li key={feature} className="flex items-start lg:col-span-1">
                        <div className="flex-shrink-0">
                          <BsCheckCircleFill className="h-5 w-5 text-purple-400" aria-hidden="true" />
                        </div>
                        <p className="ml-3 text-sm text-gray-700">{feature}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                
                <div className="mt-6">
                  <div className="rounded-md shadow">
                    <a
                      href="https://fedgen.net/phis"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                    >
                      Go to PHIS
                    </a>
                  </div>
                </div>
                <div className="mt-4 text-sm">
                  <a href="https://link.springer.com/chapter/10.1007/978-3-030-95630-1_6" className="font-medium text-gray-900">
                    See cloud Publication
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      
    </div>
  )
}
