import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { VscMenu } from 'react-icons/vsc';
import { green, red, yellow } from '@mui/material/colors';
import { Button, Stack } from '@mui/material';
import { FcPrevious } from 'react-icons/fc';

const pages = [
    {
        key: 0,
        name: 'About',
        src: '/'
    },
    {
        key: 1,
        name: 'Portfolio',
        src: '/portfolio'
    },
    {
        key: 2,
        name: 'Resume',
        src: '/resume'
    },
    {
        key: 3,
        name: 'Quotes',
        src: '/quotes'
    },
    {
        key: 4,
        name: 'Contact',
        src: '/contact'
    },
    {
      key: 5,
      name: 'Gallery',
      src: '/gallery'
    },
    {
      key: 6,
      name: 'Skills',
      src: '/skills'
    }
]

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (
    <>
    <div className='sm:hidden'>
    <AppBar position="static" color='transparent'>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
         
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'sans',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'white',
              textDecoration: 'none',
            }}
          >
            Akande Victor
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <Link key="0" to="/" ><FcPrevious /></Link>
            </IconButton>
            
          </Box>
          
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'white',
              textDecoration: 'none',
            }}
          >
            AV
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
            {pages.map((page) => (
                <Button
                key={page.name} 
                sx={{ my: 2, color: 'white', letterSpacing: '.2rem', display: 'block' }}
              >
                <Link to={page.src}>{page.name}</ Link>
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Stack direction="row" spacing={1}>
                <Avatar  sx={{ bgcolor: red[700], width: 20, height: 20, boxShadow: 4, }}>{" "}</Avatar>
                <Avatar  sx={{ bgcolor: yellow[700], width: 20, height: 20, boxShadow: 4}}>{""}</Avatar>
                <Avatar  sx={{ bgcolor: green[700], width: 20, height: 20, boxShadow: 3}}>{""}</Avatar>
            </Stack>
            
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    </div>

    <div className='hidden sm:flex overflow-hidden'>
    <AppBar position="static" color='transparent'>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
         
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'sans',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'white',
              textDecoration: 'none',
            }}
          >
            AV
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <VscMenu />
            </IconButton>
            
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem  onClick={handleCloseNavMenu}>
                  <Link key={page.key} to={page.src}>{page.name}</Link>
                </MenuItem>
              ))}
              </Menu> 
          </Box>
          
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'white',
              textDecoration: 'none',
            }}
          >
            AV
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
            {pages.map((page) => (
                <Button
                key={page.name} 
                sx={{ my: 1, color: 'white', fontWeight: 400, letterSpacing: '.1rem', display: 'block' }}
              >
                <Link to={page.src}>{page.name}</ Link>
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Stack direction="row" spacing={1}>
                <Avatar  sx={{ bgcolor: red[700], width: 20, height: 20, boxShadow: 4, }}>{" "}</Avatar>
                <Avatar  sx={{ bgcolor: yellow[700], width: 20, height: 20, boxShadow: 4}}><p className='hidden hover:flex text-black text-xl'>-</p></Avatar>
                <Avatar  sx={{ bgcolor: green[700], width: 20, height: 20, boxShadow: 3}}>{""}</Avatar>
            </Stack>
            
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    </div>
    </>
  );
};
export default NavBar;
