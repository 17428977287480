import { useState } from "react";

const resumes = [
  {
    "duration": "August 2023 - Present",
    "location": "DrugStoc",
    "href": "https://drugstoc.com",
    "role": "Back End Engineer",
    "experience": [
      "Developed the backend and collaborated with frontend and mobile engineers to build a multi modal inventory management system using Django, Celery, Redis, and PostgreSQL.",
      "CoDesigned the system architecture for multiple applications with the project manager.",
      "Managed the devops for 3 projects, setting up CI-CD pipelines to enable a stress free working environment for all developers on the team. ",
      "Pushed bug fixes and planned upgrades for applications already in the production environment.",
      "Optimized web service queries to deal with 1,000,000+ records in the database, reducing response time and eliminating gateway errors.",
      "Built custom python packages using Poetry.",
      "Developed an AI GPT-based Chatbot."
    ]
  },
  {
    "duration": "August 2023 - December 2023",
    "location": "ASPMIR (Advanced Signal Processing and Machine Intelligence Research) Lab",
    "href": "https://www.researchgate.net/lab/Advanced-Signal-Processing-and-Machine-Intelligence-Research-ASPMIR-Lab-Emmanuel-Adetiba",
    "role": "Software Engineer",
    "experience": [
      "Designed a microservice system architecture for a cloud based app built with  different technologies including Django, Golang, React, Linux, LXD.",
      "Developed the web APIs  with Django for multiple user facing applications.",
      "Developed a REST API gateway for a Linux CLI software using Golang, considering security risks associated with developing a gateway for a host machine."

    ]
  },
  {
    "duration": "December 2020 - August 2023",
    "location": "CApIC-ACE (Covenant Applied Informatics and Communication - Africa Centre of Excellence)",
    "href": "https://ace.covenantuniversity.edu.ng",
    "role": "Software Engineer",
    "experience": [
      "Managed deployment of 10 microservices and web applications with Linux, Kubernetes, Docker, and CI/CD pipelines with Jenkins.",
      "Designed and Implemented the authentication system for all in-house services using the OAuth specification.",
      "Prepared test plans and test cases for authentication microservice API endpoints using Postman.",
      "Collaborated with other engineers to build a private cloud running on Openstack.",
      "Conducted onboarding for new interns, including training on programming stack, tools, and Git workflow.",
      "Designed High Availability deployment architecture to accommodate self-healing and scaling.",
      "Optimized the operation of microservices by detecting and fixing bugs leading to an increase in the Quality of Service (QoS), client satisfaction and 95% uptime.",
      "Engineered 5 microservices using Django or Flask backend with MongoDB, and MySQL as databases and RabbitMQ for event-driven asynchronous communication.",
      "Contributed to requirements elicitation and system requirement documentation during stakeholder meetings and boot camps."


    ]
  },
  {
    "duration": "December 2018 -  August 2020",
    "location": "ASPMIR (Advanced Signal Processing and Machine Intelligence Research) Lab",
    "href": "https://www.researchgate.net/lab/Advanced-Signal-Processing-and-Machine-Intelligence-Research-ASPMIR-Lab-Emmanuel-Adetiba",
    "role": "Software Engineer",
    "experience": [
      "Used Linux, Python, Django, and MySQL to build a REST API gateway for an open-source project OpenBTS.",
      "Designed a user interface with HTML, CSS, and Javascript to interface with the REST API gateway built for an open source project, OpenBTS.",
      "Developed anomaly detection ML model for a private cloud infrastructure utilizing server logs data with 2,000,000+ entries."
    ]
  },
  {
    "duration": "June 2018 -  November 2018",
    "location": "Covenant University",
    "href": "https://covenantuniversity.edu.ng",
    "role": "Research Assistant",
    "experience": [
      "Used Matlab and Python to build Machine Learning models and evaluate their performance.",
      "Implemented image processing algorithms for computer vision in Matlab."
    ]
  }

]

const Item = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div className="border animate-fade-up rounded shadow-sm">
        <button
          type="button"
          aria-label="Open item"
          title="Open item"
          className="flex items-center justify-between w-full p-4 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <p className="text-lg font-medium text-white">{title}</p>
          <div className="flex items-center justify-center w-8 h-8 border rounded-full">
            <svg
              viewBox="0 0 24 24"
              className={`w-3 text-fuchsia-600 transition-transform duration-200 ${
                isOpen ? 'transform rotate-180' : ''
              }`}
            >
              <polyline
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeMiterlimit="10"
                points="2,7 12,17 22,7"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </button>
        {isOpen && (
          <div className="p-4 pt-0">
            <p className="text-white">{children}</p>
          </div>
        )}
      </div>
    );
  };
  
  export const Resume = () => {
    return (
      <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-24 lg:px-8 lg:py-20">
        <div class="max-w-xl sm:mx-auto lg:max-w-2xl">
          <div class="flex flex-col mb-16 sm:text-center">
            <div class="max-w-xl md:mx-auto sm:text-center lg:max-w-2xl">
              <h2 class="max-w-lg mb-3 font-serif text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
                <span class="relative inline-block">
                  <svg
                    viewBox="0 0 52 24"
                    fill="currentColor"
                    class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-fuchsia-500 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                  >
                    <defs>
                      <pattern
                        id="ec5d8ef5-b853-4714-b94f-df28ec98eeb7"
                        x="0"
                        y="0"
                        width=".135"
                        height=".30"
                      >
                        <circle cx="1" cy="1" r=".7" />
                      </pattern>
                    </defs>
                    <rect
                      fill="url(#ec5d8ef5-b853-4714-b94f-df28ec98eeb7)"
                      width="52"
                      height="24"
                    />
                  </svg>
                  <span class="relative">My</span>
                </span>{' '}
                Resume
              </h2>
              <p class="text-base text-white md:text-lg">
                Summary of my work exprience. To get the full gist click  <a  alt="resume" href='https://linkedin.com/in/victor-akande-570a35148/' className="hover:text-fuchsia-600 hover:bg-fuchsia-100 hover:rounded-sm">HERE!</a>
              </p>
            </div>
          </div>
          <div class="space-y-4">
            {resumes.map((resume, idx) => (
            <Item key={idx} title={resume.duration}>
                <p className="font-sans font-medium text-fuchsia-300">{resume.role} | <em className="text-gray-400 hover:text-fuchsia-300"><a href={resume.href}>{resume.location}</a></em></p>
                <ul className="space-y-2">
                  {resume.experience.map((exp, idx) => (
                  <li key={idx}>● {exp}</li>
                  ))}
                </ul>
            </Item>))}
          </div>
        </div>
      </div>
    );
  };